@import '../../assets/scss/variables.scss';

// **** Case Updates Tab CSS **** //
.note-message{
    background-color: #f9f9f9;
    padding: 10px 15px;
    position: relative;
    border-radius: 5px;
}
.case-updates-tab{
    font-family: $font-base;
    height: calc(100% - 285px);
    min-height: calc(100vh - 285px);
    padding: 25px 70px;
    h1{
        font-size: 20px;
        font-weight: 500;
        color: $text-40;
        font-family: $font-secondary;
        margin: 0px;
    }
    .search-bar{
        width: 100%;
        margin-top: 1rem;
        .search-box{
            border-radius: 8px;
            border: 1px solid $bg-40;
            background: $white;
            padding: 12px 14px 12px 40px;
            font-size: 14px;
            font-weight: 400;
            color: $text-30;
        }
    }
    .export-block{
        position: absolute; 
        right: 0px;
        top: -83px;
        .export-button{
            background: $white;
            padding: 6px 10px 6px 8px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            .p-button-icon{
                color: $text-40;
                font-size: 12px;
            }
            &:hover{
                background: $text-40;
                .p-button-label{
                    color: $white;
                }
                .p-button-icon{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
        &.export-analyst{
            position: initial;
        }
    }
    .loading-section{
        display: flex;
        align-items: center;
        min-height: 500px;
        text-align: center;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        p{
            font-size: 15px;
            color: $text-30;
            font-weight: 400;
            margin-top: 1rem;
            display: inline-block;
        }
        .small-box{
            border-radius: 5px;
            border: 1px solid #D2D9E8;
            opacity: 0.7;
            background: #E4E9F1;
            margin: 18px auto;
            width: 200px;
            padding: 5px 15px;
            .bars{
                background: #D3D3D8;
                width: 135px !important;
                height: 3px !important;
                margin: 5px 0px;
                &.custom-width{
                    width: 105px !important;  
                }
            }
            .pi{
                font-size: 14px;
                font-weight: 600;
                color: #D2D9E8;
            }
        }
        .big-box{
            border-radius: 5px;
            border: 1px solid #B0C2D8;
            background: #E2E8F3;
            margin: 18px auto;
            width: 320px;
            padding: 5px 15px;
            .bars{
                background: #C0C0CB;
                width: 165px !important;
                height: 4px !important;
                margin: 5px 0px;
                &.custom-width{
                    width: 125px !important;  
                }
            }
            .pi{
                font-size: 14px;
                font-weight: 600;
                color: #ADADBA;
            }
        }
    }
    .results-section{
        margin-top: 25px;
        .heading-p{
            font-size: 14px;
            font-weight: 400;
            color: $text-30;
            margin: 0px;
        }
        .result-box{
            border-radius: 8px;
            border: 1px solid $bg-40;
            background: $white;
            padding: 24px;
            margin-top: 15px;
            .status{
                font-size: 12px;
                font-weight: 500;
                padding: 4px 10px;
                align-items: center;
                border-radius: 16px;
                display: inline-flex;
                border: 1.5px solid $text-30;
                &.error{
                    border: 1.5px solid $danger-20;
                    color: $danger-20;
                }
                &.warning{
                    border: 1.5px solid $warning-20;
                    color: $warning-20;
                }
                &.success{
                    border: 1.5px solid $success-20;
                    color: $success-20;
                }
                &.not-started{
                    border: 1.5px solid $gray-700;
                    color: $gray-700;
                }
            }
            .alert-number-span{
                font-size: 14px;
                font-weight: 500;
                color: $text-40;
                text-decoration: underline;
            }
            .header-info{
                h2{
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 600;
                    color: $text-40;
                    line-height: 22px;
                }
                .icon{
                    line-height: 0px;
                }
                ul{
                    margin: 25px 0px 10px;
                    padding: 0px;
                    li{
                        list-style: none;
                        display: inline-block;
                        border-radius: 4px;
                        background: $bg-30;
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                        padding: 4px 8px;
                        margin-right: 12px;
                        margin-bottom: 10px;
                        &:last-child{
                            margin-right: 0px;
                        }
                    }
                }
            }
            .notes-block{
                span{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }
                div {
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-40;
                    line-height: 22px;
                    margin: 0px 0px 0px;
                    text-align: justify;
                }
            }
            .activity-section{
                padding: 24px;
                border-radius: 8px;
                background: #F8F8F8;
                height: 100%;
                p{
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }
                ul{
                    margin: 0px 0px 40px;
                    padding: 0px;
                    position: relative;
                    li{
                        font-size: 12px;
                        font-weight: 500;
                        color: $text-40;
                        span{
                            padding: 3px 10px;
                            align-items: center;
                            border-radius: 16px;
                            display: inline-flex;
                            border: 1px solid $text-20;
                            background: $bg-30;
                        }
                    }
                    &:last-child{
                        margin: 0px;
                        &::after{
                            display: none;
                        }
                    }
                    &::after{
                        content: "";
                        background: url(../../assets/images/down-arrow.png);
                        width: 8px;
                        height: 17px;
                        position: absolute;
                        bottom: -30px;
                        left: 10px;
                    }
                }
            }
            .case-update-icon{
                .p-image{
                    line-height: 0px;
                    display: block;
                    img{
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    }
}
.caseIdsGrid{
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    .alert-number-span{
        .status{
            font-size: 12px !important;
            color: $text-30;
            &:hover{
                border-color: $black;
            }
        }
    }
}
.dailog-title-data{
    h2{
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    color: #151518;
    font-family: "Roboto Slab", serif;
    }
}