@import '../../../assets/scss/variables.scss';

// **** News Tab CSS **** //
.back-button {
    padding: 20px 70px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: white;
    margin-bottom: 5px;
    color: #6E6E77;

    a {
        color: #6E6E77;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-size: 13px;

        &:hover {
            color: #6E6E77;
        }

        .pi-arrow-left {
            margin-right: 5px;
            font-size: 13px;


        }
    }
}


.news-tab {
    margin-top: 20px;
    .grid {
        justify-content: center;
    }
    .slider-wrapper {
        position: relative;

        .type {
            border-radius: 16px;
            border: 1px solid #A9A9A9;
            background: rgba(30, 30, 30, 0.30);
            padding: 2px 10px;
            font-size: 12px;
            font-weight: 500;
            color: #FFF7F3;
            right: 25px;
            position: absolute;
            top: 25px;
            z-index: 1;
        }

        .news-slider {
            position: relative;

            .p-galleria-caption {
                background: none;
                padding: 20px 25px;

                .description {
                    width: 89%;
                }

                .category {
                    border-radius: 16px;
                    background: rgba(255, 255, 255, 0.20);
                    padding: 5px 10px;
                    color: #FFF7F3;
                    font-size: 12px;
                    font-weight: 500;
                }

                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: $white;
                    font-family: $font-secondary;
                    margin-top: 20px;
                }

                .description {
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $white;
                    margin-top: 8px;
                    line-height: 21px;
                }
            }

            .p-galleria-thumbnail-wrapper {
                .p-galleria-thumbnail-container {
                    background: none;
                    position: absolute;
                    right: 25px;
                    bottom: 6px;

                    .p-link {
                        border: 1px solid rgba(208, 208, 211, 0.20);
                        background: rgba(255, 255, 255, 0.10);
                        margin: 5px;

                        &.p-disabled {
                            svg {
                                color: $primary-50;
                            }
                        }
                    }
                }
            }
        }
    }

    .updates-section {
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $bg-30;
        padding: 18px;
        margin-top: 1rem;

        .icon-wrapper {
            border-radius: 8px;
            background: $white;
            padding: 16px;
            width: 70px;
            height: 70px;
        }

        h2 {
            font-size: 15px;
            font-weight: 600;
            color: $text-40;
            margin: 0px 0px 8px;
        }

        p {
            font-size: 13px;
            font-weight: 500;
            color: $text-40;
            margin: 0px;
            line-height: 20px;
        }

        .see-all-button {
            background: $white;
            padding: 8px 12px 8px 14px;
            border-radius: 4px;
            border: 1px solid $bg-40;

            .p-button-label {
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }

            .p-button-icon {
                color: $text-40;
                font-size: 12px;
            }

            &:hover {
                background: $text-40;

                .p-button-label {
                    color: $white;
                }

                .p-button-icon {
                    color: $white;
                }
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .new-list-section {
        h1 {
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            &:first-letter {
                text-transform: uppercase;
              }
        }

        .date-calendar {
            border: 1px solid #d1d5db;
            border-radius: 6px;

            .p-datepicker-trigger {
                background: $white;
                padding: 7px 6px 7px 12px;
                width: auto;
                color: $text-40;
                border: none;
                border-right: 0px;

                &:focus {
                    box-shadow: none;
                }
            }

            .p-inputtext {
                padding: 7px 14px 7px 6px;
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
                border: 0px;

                &:focus {
                    box-shadow: none;
                }
            }

            &:hover {
                border-color: $text-20;
            }
        }

        .search-bar {
            .search-box {
                border-radius: 8px;
                border: 1px solid $bg-40;
                background: $white;
                padding: 8px 14px 8px 40px;
                font-size: 13px;
                font-weight: 400;
                color: $text-30;
                height: 36px;
            }
        }

        .list-wrapper {
            padding: 25px;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid $bg-40;
            background: $white;
            margin-top: 1rem;

            .news-list {
                .custom-width {
                    width: 95%;
                }

                .news-img {
                    display: flex;

                    img {
                        width: 100%;
                        border-radius: 8px;
                        height: auto;
                        max-height: 126px;
                        object-fit: cover;
                    }
                }

                h3 {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0px;
                    line-height: 23px;
                    color: $text-40;
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-30;
                    line-height: 22px;
                    margin: 10px 0px 0px;

                    a {
                        color: $text-40;

                        &:hover {
                            color: $primary-60;
                        }
                    }
                }

                ul {
                    margin: 15px 0px 0px;
                    padding: 0px;

                    li {
                        font-size: 13px;
                        font-weight: 400;
                        color: $text-40;

                        .pi {
                            font-size: 13px;
                            color: $text-30;
                            margin-right: 5px;
                        }

                        span {
                            &.author {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .pagination {
            padding: 20px 25px;
            background: $white;
            border-radius: 0px 0px 8px 8px;
            border: 1px solid $bg-40;
            border-top: none;
            background: $bg-30;

            span {
                font-size: 13px;
                font-weight: 500;
                color: $text-30;
            }

            ul {
                padding: 0px;
                margin: 0px;

                li {
                    text-align: center;
                    line-height: 25px;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: $text-40;

                    &.disabled {
                        color: $text-30;
                        opacity: 0.8;
                        pointer-events: none;
                    }

                    &:hover {
                        color: $primary-40;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .news-right-section {
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;

        .header-section {
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;

            h3 {
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;

                .pi {
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .upcoming-news {
            padding: 25px;

            .news-img {
                img {
                    width: 100%;
                }
            }

            h4 {
                font-size: 13px;
                font-weight: 500;
                color: $text-30;
            }

            p {
                margin: 0px;
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
                line-height: 21px;
            }

            ul {
                margin: 0px;
                padding: 0px 25px;

                li {
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .categories-section {
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        .header-section {
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;

            h3 {
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;

                .pi {
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .categories-list {
            padding: 25px;

            ul {
                padding: 0px;
                margin: 0px 0px 25px;

                li {
                    font-size: 13px;
                    font-weight: 600;
                    color: $text-40;

                    &:last-child {
                        font-weight: 500;
                        color: $text-30;
                    }

                    .dot {
                        width: 7px;
                        height: 7px;
                        border-radius: 25px;
                        background: $gray-600;
                        display: inline-block;
                        margin-right: 5px;

                        &.red-color {
                            background: $primary-50;
                        }

                        &.green-color {
                            background: $success-20;
                        }

                        &.blue-color {
                            background: #529FC0;
                        }

                        &.yellow-color {
                            background: $warning-20;
                        }

                        &.purple-color {
                            background: #C764BD;
                        }
                    }
                }

                &:last-child {
                    margin: 0px 0px 0px;
                }
            }
        }
    }

    .new-view-section {
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        .header-section {
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;

            h3 {
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;

                .pi {
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .viewed-list {
            padding: 25px;

            .news-img {
                .p-image {
                    img {
                        max-width: 152px;
                        height: 83px;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
            }

            .news-heading {
                h3 {
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-40;
                    line-height: 23px;
                }
            }
        }
    }

    &.news-details-section {

        h1 {
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            margin: 0px 0px 20px;
        }

        .new-list-section {
            .list-wrapper {
                border-radius: 8px;
                margin-top: 0px;

                .news-list {
                    ul {
                        margin: 0px 0px 25px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: $text-40;
                        line-height: 23px;
                        &:first-letter {
                            text-transform: uppercase;
                          }
                    }

                    .attachments {
                        margin-top: 25px;

                        .heading-span {
                            display: block;
                            font-size: 13px;
                            font-weight: 500;
                            color: $text-40;
                            margin-bottom: 15px;
                        }

                        .download-button {
                            background: $bg-20;
                            padding: 8px 12px 8px 14px;
                            border-radius: 4px;
                            border: 1px solid $bg-40;
                            display: flex;
                            margin-top: 10px;

                            .p-button-label {
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }

                            .p-button-icon {
                                color: $text-40;
                                font-size: 12px;
                            }

                            &:hover {
                                background: $text-40;

                                .p-button-label {
                                    color: $white;
                                }

                                .p-button-icon {
                                    color: $white;
                                }
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .news-img {
                        img {
                            height: auto;
                            max-height: inherit;
                        }
                    }
                }
            }
        }

        .new-view-section {
            margin-top: 0px;
        }
    }

}